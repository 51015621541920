import Section, { SectionBackground } from "@liberetech/design-system/Section";
import SectionHeader from "@liberetech/design-system/SectionHeader";
import classNames from "classnames";
import { ReactNode } from "react";

import OverlappedImages from "./OverlappedImages/OverlappedImages";
import styles from "./SectionWithOverlappedImages.module.css";

const SectionWithOverlappedImages: React.FC<
  SectionWithOverlappedImagesProps
> = ({
  background,
  title,
  titleAs,
  label,
  description,
  images: { above, below },
  inverted,
  children,
  ...rest
}) => (
  <Section background={background} {...rest}>
    <div
      className={classNames(styles.wrap, "grid", {
        [styles.inverted]: inverted,
      })}
    >
      <SectionHeader
        title={title}
        titleAs={titleAs}
        label={label}
        description={description}
        descriptionNoProse
      />
      <OverlappedImages
        above={above}
        below={below}
        inverted={!inverted}
        className={styles.images}
      />
    </div>
    {children}
  </Section>
);

export default SectionWithOverlappedImages;

type SectionWithOverlappedImagesProps = {
  background?: SectionBackground;
  className?: string;
  title: string;
  titleAs?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  label?: string;
  description?: string | ReactNode;
  images: {
    above: string;
    below: string;
  };
  inverted?: boolean;
  id?: string;
};
