import { Paper, Section, SectionHeader } from "@liberetech/design-system";

import styles from "./SectionAmenities.module.css";
import SectionAmenitiesAmenity from "./SectionAmenitiesAmenity/SectionAmenitiesAmenity";

const SectionAmenities: React.FC<SectionAmenitiesProps> = ({
  label,
  title,
  description,
  mainAmenitiesTitle,
  mainAmenities,
  secondaryAmenities,
  footnotes,
}) => {
  return (
    <Section background="contrast" id="amenities">
      <div className="grid">
        <SectionHeader
          centered
          title={title}
          label={label}
          description={description}
        />
        <Paper className={styles.amenitiesMain} elevation={1}>
          {mainAmenitiesTitle && (
            <h3 className={styles.amenitiesMainTitle}>{mainAmenitiesTitle}</h3>
          )}
          <ul className={styles.amenitiesMainList}>
            {mainAmenities.map((amenity, i) => (
              <SectionAmenitiesAmenity key={i} {...amenity} main />
            ))}
          </ul>
        </Paper>
        {secondaryAmenities && (
          <ul className={styles.amenitiesSecondary}>
            {secondaryAmenities.map((amenity, i) => (
              <SectionAmenitiesAmenity key={i} {...amenity} />
            ))}
          </ul>
        )}
        {footnotes && (
          <ul className={styles.amenitiesFootnotes}>
            {footnotes.map((footnote, i) => (
              <li key={i}>
                <sup>{i + 1}</sup> {footnote.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Section>
  );
};

type SectionAmenitiesProps = {
  label: string;
  title: string;
  description?: string;
  mainAmenities: Array<{
    icon: React.ReactNode;
    title: string;
    titleSup?: number;
  }>;
  mainAmenitiesTitle?: string;
  secondaryAmenities?: Array<{
    icon: React.ReactNode;
    title: string;
    titleSup?: number;
    description?: string;
  }>;
  footnotes?: Array<{ text: string }>;
};

export default SectionAmenities;
