import { Icon } from "@liberetech/design-system";
import classNames from "classnames";

import styles from "./SectionAmenitiesAmenity.module.css";

const SectionAmenitiesAmenity: React.FC<SectionAmenitiesAmenityProps> = ({
  icon,
  main,
  title,
  titleSup,
  description,
}) => (
  <li className={classNames(styles.amenity, { [styles.amenityMain]: main })}>
    {main ? <Icon type="contrast">{icon}</Icon> : icon}
    <div>
      <p>
        {title}
        {titleSup && <sup>{titleSup}</sup>}
      </p>
      {description && (
        <p className={styles.amenityDescription}>{description}</p>
      )}
    </div>
  </li>
);

type SectionAmenitiesAmenityProps = {
  icon: React.ReactNode;
  main?: boolean;
  title: string;
  titleSup?: number;
  description?: string;
};

export default SectionAmenitiesAmenity;
