import { Section, SectionHeader } from "@liberetech/design-system";

import Carousel from "./Carousel";
import styles from "./CarouselSection.module.css";

const CarouselSection: React.FC<CarouselSectionProps> = ({
  label,
  title,
  description,
  centered,
  children,
  ...props
}) => {
  return (
    <Section {...props}>
      <SectionHeader
        className="grid"
        label={label}
        title={title}
        description={description}
        centered={centered}
      />
      <Carousel
        classNames={{
          root: styles.carousel,
          images: styles.carouselImages,
          buttons: styles.carouselButtons,
          button: styles.carouselButton,
        }}
      >
        {children}
      </Carousel>
    </Section>
  );
};

type CarouselSectionProps = {
  className?: string;
  label?: string;
  title: string;
  description?: React.ReactNode;
  centered?: boolean;
  children: React.ReactNode;
};

export default CarouselSection;
