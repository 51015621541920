import { ActionSheet, Prose } from "@liberetech/design-system";

import { useTranslation } from "lib/i18n/client";

const SectionReviewsAbout: React.FC<SectionReviewsAboutProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation("sectionReviews");
  return (
    <ActionSheet
      title={t("about.title")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Prose>{t("about.description")}</Prose>
    </ActionSheet>
  );
};

type SectionReviewsAboutProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

export default SectionReviewsAbout;
