import { Children, useEffect, useRef, useState } from "react";
import { debounce } from "throttle-debounce";

const Carousel: React.FC<CarouselProps> = ({ classNames, children }) => {
  const carouselEl = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<number>(0);
  const [hasReachedToEnd, setReachedToEnd] = useState<boolean>(true);
  useEffect(() => {
    if (carouselEl.current) {
      setReachedToEnd(checkHasReachedToEnd(carouselEl.current));
    }
  }, []);
  const onNext = () => {
    if (!carouselEl?.current || carouselEl.current.children.length === 0) {
      return;
    }

    carouselEl.current.scrollTo({
      left:
        carouselEl.current.scrollLeft +
        carouselEl.current.children[0].getBoundingClientRect().width,
      behavior: "smooth",
    });
  };

  const onPrev = () => {
    if (!carouselEl?.current || carouselEl.current.children.length === 0) {
      return;
    }

    carouselEl.current.scrollTo({
      left:
        carouselEl.current.scrollLeft -
        carouselEl.current.children[0].getBoundingClientRect().width,
      behavior: "smooth",
    });
  };

  const updateScrollPosition = debounce(100, (ev: Event) => {
    const carouselEl = ev?.target as HTMLDivElement | null;
    if (carouselEl != null && carouselEl.children.length > 0) {
      setPosition(
        Math.round(
          carouselEl.scrollLeft /
            carouselEl.children[0].getBoundingClientRect().width,
        ),
      );
      setReachedToEnd(checkHasReachedToEnd(carouselEl));
    }
  });

  useEffect(() => {
    // Delay listening to avoid initial load displacement
    setTimeout(() => {
      carouselEl.current?.addEventListener("scroll", updateScrollPosition);
    }, 100);

    return () => {
      carouselEl.current?.removeEventListener("scroll", updateScrollPosition);
    };
  }, [carouselEl.current]);

  return (
    <div className={classNames?.root}>
      <div className={classNames?.images} ref={carouselEl}>
        {children}
      </div>
      {Children.count(children) > 1 && (
        <div className={classNames?.buttons}>
          <button
            className={classNames?.button}
            onClick={onPrev}
            disabled={position === 0}
          >
            <IconArrowLeft />
          </button>
          <button
            className={classNames?.button}
            onClick={onNext}
            disabled={hasReachedToEnd}
          >
            <IconArrowRight />
          </button>
        </div>
      )}
    </div>
  );
};

const checkHasReachedToEnd = (carouselEl: HTMLDivElement) => {
  if (!carouselEl) {
    return true;
  }

  const contentWidth =
    [...carouselEl.children].reduce(
      (prev, c) => prev + c.getBoundingClientRect().width + 20,
      0,
    ) - 20;

  const computedStyles = getComputedStyle(carouselEl);
  const containerWidth =
    carouselEl.getBoundingClientRect().width -
    parseFloat(computedStyles.paddingLeft) -
    parseFloat(computedStyles.paddingRight);

  return Math.floor(contentWidth - carouselEl.scrollLeft - containerWidth) <= 0;
};

type CarouselProps = {
  classNames?: {
    root?: string;
    images?: string;
    buttons?: string;
    button?: string;
  };
};

const IconArrowLeft = () => (
  <svg viewBox="0 0 10 16">
    <path d="M8.11 14.22L1.9 8 8.1 1.78" />
  </svg>
);

const IconArrowRight = () => (
  <svg viewBox="0 0 16 16">
    <path d="M4.89 1.78L11.1 8 4.9 14.22" />
  </svg>
);

export default Carousel;
