import {
  NavLink,
  Section,
  SectionHeader,
  SectionProps,
} from "@liberetech/design-system";
import { ReactNode } from "react";

import CardDaVinci from "components/CardDaVinci/CardDaVinci";
import ImageCDN from "components/ImageCDN/ImageCDN";
import { useLanguage, useTranslation } from "lib/i18n/client";

import styles from "./SectionSpecialRates.module.css";

const SectionSpecialRates: React.FC<SectionSpecialRatesProps> = ({
  label,
  title,
  description,
  ...rest
}) => {
  const { t } = useTranslation("common");
  const lang = useLanguage();
  return (
    <Section className="grid" {...rest}>
      <SectionHeader
        label={label}
        title={title}
        description={description}
        centered
      />
      <div className={styles.segments}>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.midStay")}`}
          onClick={() => {
            notifyClick("mid_stay");
          }}
        >
          <CardDaVinci
            title={t("segments.midStay")}
            image={
              <ImageCDN
                src="https://air-production-cms-uploads.storage.googleapis.com/uploads/2021/09/13141939/pexels-tatiana-syrikova-3975583-2.jpg"
                alt=""
                loading="lazy"
                sizes="50vw"
              />
            }
          />
        </NavLink>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.groupTravel")}`}
          onClick={() => {
            notifyClick("groups");
          }}
        >
          <CardDaVinci
            title={t("segments.groups")}
            image={
              <ImageCDN
                src="https://air-production-cms-uploads.storage.googleapis.com/uploads/2021/07/01101150/Viajes-en-grupo-Libere-group-travel-low.jpg"
                alt=""
                loading="lazy"
                sizes="50vw"
              />
            }
          />
        </NavLink>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.travelAgencies")}`}
          onClick={() => {
            notifyClick("travel_agencies");
          }}
        >
          <CardDaVinci
            title={t("segments.agencies")}
            image={
              <ImageCDN
                src="https://air-production-cms-uploads.storage.googleapis.com/uploads/2021/06/22072928/libere-travel-agencies-hero.jpeg"
                alt=""
                loading="lazy"
                sizes="50vw"
              />
            }
          />
        </NavLink>
        <NavLink
          href={`/${lang}/${t("common:pages.slug.businessTravel")}`}
          onClick={() => {
            notifyClick("business");
          }}
        >
          <CardDaVinci
            title={t("segments.business")}
            image={
              <ImageCDN
                src="https://air-production-cms-uploads.storage.googleapis.com/uploads/2021/07/21135946/Libere-Business-Travelers-web.jpg"
                alt=""
                loading="lazy"
                sizes="50vw"
              />
            }
          />
        </NavLink>
      </div>
    </Section>
  );
};

const notifyClick = (label: string) => {
  window.dataLayer.push({
    event: "click",
    event_category: "section_special_rates",
    event_label: label,
  });
};

type SectionSpecialRatesProps = {
  title: string;
  description?: string | ReactNode;
  label: string;
} & SectionProps;

export default SectionSpecialRates;
