import classNames from "classnames";

import ImageCDN from "components/ImageCDN/ImageCDN";

import styles from "./OverlappedImages.module.css";

const OverlappedImages: React.FC<OverlappedImagesProps> = ({
  above,
  below,
  className,
  inverted,
}) => (
  <div
    className={classNames(styles.overlappedImages, className, {
      [styles.overlappedImagesInverted]: inverted,
    })}
  >
    <div
      className={classNames(
        styles.overlappedImage,
        styles.overlappedImageAbove,
      )}
    >
      <ImageCDN
        src={above}
        sizes="(min-width: 1024px) 30vw, (min-width: 768px) 40vw, 55vw"
        loading="lazy"
      />
    </div>
    <div
      className={classNames(
        styles.overlappedImage,
        styles.overlappedImageBelow,
      )}
    >
      <ImageCDN
        src={below}
        sizes="(min-width: 1024px) 30vw, (min-width: 768px) 40vw, 55vw"
        loading="lazy"
      />
    </div>
  </div>
);

type OverlappedImagesProps = {
  above: string;
  below: string;
  className?: string;
  inverted?: boolean;
};

export default OverlappedImages;
