import { ReactNode } from "react";

import styles from "./CardDaVinci.module.css";

const CardDaVinci: React.FC<CardDaVinciProps> = ({
  title,
  label,
  image,
  tag,
}) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>{image}</div>
      <div className={styles.cardContent}>
        {label && <span className={styles.cardLabel}>{label}</span>}
        <h3 className={styles.cardTitle}>{title}</h3>
        {tag && <div className={styles.cardTag}>{tag}</div>}
      </div>
    </div>
  );
};

type CardDaVinciProps = {
  title: string;
  label?: string;
  image: ReactNode;
  tag?: ReactNode;
};

export default CardDaVinci;
