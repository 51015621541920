const imageProxyPath = `https://image-proxy.libere.app/images`;

const buildImageUrl = (src: string, format: string, size: number) =>
  `${imageProxyPath}/${format}:${size}/plain/${src}@${format}`;

const buildImageSrcSet = (
  src: string,
  format: string,
  sizes: Array<number>,
): string =>
  sizes
    .map((size) => {
      return `${buildImageUrl(src, format, size)} ${size}w`;
    })
    .join(" ,");

const ImageCDN: React.FC<ImageCDNProps> = ({
  src,
  sizes,
  className,
  imgClassName,
  ...rest
}) => {
  if (!src) {
    return null;
  }

  return (
    <picture className={className}>
      <source
        srcSet={buildImageSrcSet(src, "webp", [1920, 1024, 768, 400])}
        sizes={sizes}
        type="image/webp"
      />
      <img
        className={imgClassName}
        srcSet={buildImageSrcSet(src, "jpg", [1920, 1024, 768, 400])}
        src={buildImageUrl(src, "jpg", 1920)}
        sizes={sizes}
        {...rest}
      />
    </picture>
  );
};

type ImageCDNProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  imgClassName?: string;
  src: string;
  sizes: string;
};

export default ImageCDN;
