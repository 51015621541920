import { Link } from "@liberetech/design-system";
import classNames from "classnames";

import CarouselSection from "components/Carousel/CarouselSection";
import useActionSelector from "components/useActionSelector/useActionSelector";
import { Review, ReviewPlatform } from "gql/types";
import { Trans, useTranslation } from "lib/i18n/client";

import styles from "./SectionReviews.module.css";
import SectionReviewsAbout from "./SectionReviewsAbout/SectionReviewsAbout";

const SectionReviews: React.FC<SectionReviewsProps> = ({
  reviews,
  label,
  title,
  averageRating,
  reviewCount,
  ...rest
}) => {
  const { currentAction, isActionOpen, closeAction, setAction } =
    useActionSelector<SectionReviewsAction>();
  const { t } = useTranslation("sectionReviews");

  if (!reviews) {
    return null;
  }

  return (
    <>
      <CarouselSection
        {...rest}
        label={label}
        title={title}
        description={
          <span className={styles.description}>
            <IconStar />
            <span>
              <Trans
                i18nKey="sectionReviews:description"
                components={{
                  strong: <strong />,
                  linkDetails: (
                    <ButtonLink
                      onClick={() => {
                        setAction({ type: "about" });
                      }}
                    />
                  ),
                }}
                values={{ averageRating, count: reviewCount }}
              />
            </span>
          </span>
        }
        centered
      >
        {reviews.map((review, i) => (
          <div className={styles.card} key={i}>
            <div className={styles.cardRating}>
              {Array(5)
                .fill("")
                .map((_, i) => (
                  <div
                    className={classNames(styles.cardRatingStar, {
                      [styles.cardRatingStarOn]: i + 1 - review.rating <= 0,
                    })}
                  >
                    <IconStar />
                  </div>
                ))}
            </div>
            <p className={styles.cardReviewer}>{review.reviewer.name}</p>
            <p className={styles.cardTitle}>“{review.content}”</p>
            {review.platform != ReviewPlatform.Unspecified && (
              <p className={styles.cardReviewPlatform}>
                <img
                  className={styles.cardReviewPlatformImage}
                  src={`/static/images/review_platform/${review.platform.toLowerCase()}.jpg`}
                />
                <span>
                  {t("channel", {
                    channel: reviewPlatformToString(review.platform),
                  })}
                </span>
              </p>
            )}
          </div>
        ))}
      </CarouselSection>
      {currentAction?.type === "about" && (
        <SectionReviewsAbout
          isOpen={isActionOpen}
          onRequestClose={closeAction}
        />
      )}
    </>
  );
};

const ButtonLink: React.FC<{ onClick: () => void }> = ({
  children,
  ...rest
}) => (
  <button {...rest}>
    <Link>{children}</Link>
  </button>
);

const IconStar = () => (
  <svg viewBox="0 0 25 25">
    <path d="m12.5 2.814 3.245 6.574L23 10.443l-5.25 5.117 1.239 7.226-6.489-3.411-6.489 3.411L7.25 15.56 2 10.443l7.255-1.055L12.5 2.814Z" />
  </svg>
);

const reviewPlatformToString = (platform: ReviewPlatform) => {
  switch (platform) {
    case ReviewPlatform.BookingCom:
      return "Booking.com";
    case ReviewPlatform.Tripadvisor:
      return "Tripadvisor";
    case ReviewPlatform.GoogleMaps:
      return "Google";
    case ReviewPlatform.Airbnb:
      return "Airbnb";
    default:
      return "Online";
  }
};

type SectionReviewsAction = { type: "about" };

type SectionReviewsProps = React.HTMLProps<HTMLDivElement> & {
  reviews?: Pick<Review, "content" | "rating" | "reviewer" | "platform">[];
  averageRating?: number;
  reviewCount?: number;
  title: string;
  label: string;
};

export default SectionReviews;
